import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeComponent } from './home.component';
import { AboutSectionComponent } from './about-section/about-section.component';
import { OfferSectionComponent } from './offer-section/offer-section.component';
import { QuotesSectionComponent } from './quotes-section/quotes-section.component';
import { HomeSectionComponent } from './home-section/home-section.component';
import { EventsSectionComponent } from './events-section/events-section.component';
import { AppRoutingModule } from './../app.routing';

@NgModule({
  declarations: [HomeComponent, AboutSectionComponent, OfferSectionComponent, QuotesSectionComponent, HomeSectionComponent, EventsSectionComponent],
  imports: [
    CommonModule,
    AppRoutingModule
  ]
})
export class HomeModule { }
