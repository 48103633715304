import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'quotes-section',
  templateUrl: './quotes-section.component.html',
  styleUrls: ['./quotes-section.component.scss']
})
export class QuotesSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
